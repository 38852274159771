import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getAuth, connectAuthEmulator, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { connectFirestoreEmulator } from 'firebase/firestore';

let firebaseApp: firebase.app.App;
let db: firebase.firestore.Firestore;

let isFirestoreReady = false;
let firestoreReadyPromise: Promise<void>;

const init = async (): Promise<void> => {
  if (firebaseApp && db && isFirestoreReady) {
    return;
  }

  if (!firestoreReadyPromise) {
    firestoreReadyPromise = (async () => {
      try {
        console.log('Initializing Firebase app...');
        if (!firebaseApp) {
          firebaseApp = firebase.initializeApp({
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseUrl: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENVER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
          });

          console.log('firebase app initialized');
        }

        console.log('Setting Firebase Auth persistence...');
        // Persist login across browser sessions
        try {
          await setPersistence(getAuth(firebaseApp), browserLocalPersistence);
          console.log('Firebase Auth persistence set successfully');
        } catch (err) {
          console.error('Error setting Firebase Auth persistence:', err);
        }

        if (!db) {
          console.log('Initializing firestore...');
          db = firebase.firestore();
          // firebase.firestore.setLogLevel('debug');
        }

        console.log('Enabling firestore network');
        // await db.disableNetwork();
        await db.enableNetwork();
        // console.log('Firestore state reset successfully');

        // try {
        //   await db.enablePersistence({ synchronizeTabs: true });
        //   console.log('Offline persistence enabled');
        // } catch (error: any) {
        //   Sentry.captureException(new Error(`Firebase persistence error: ${error} ${error.code}`));
        //   if (error.code === 'failed-precondition') {
        //     console.warn('Offline persistence failed: Multiple tabs open');
        //   } else if (error.code === 'unimplemented') {
        //     console.warn('Offline persistence not supported in this environment');
        //   } else {
        //     console.error('Error enabling offline persistence:', error);
        //   }
        // }

        // this is for local testing
        if (process.env.REACT_APP_FIRESTORE_EMULATOR === 'YES') {
          console.log('Firestore emulator enabled');
          console.log('Firestore emulator host:', process.env.REACT_APP_FIRESTORE_EMULATOR_HOST);
          const host = process.env.REACT_APP_FIRESTORE_EMULATOR_HOST || 'localhost';
          const auth = getAuth();

          connectFirestoreEmulator(db, host, 8080);
          connectAuthEmulator(auth, `https://${host}`);
        }

        isFirestoreReady = true;
        console.log('Firestore is ready');
      } catch (err) {
        console.error('Error initializing Firestore:', err);
        throw err;
      }
    })();
  }
  return firestoreReadyPromise;
};

const ensureFirestoreInitialized = async (): Promise<void> => {
  await init();
};

const app = (): firebase.app.App => {
  if (!firebaseApp) {
    throw new Error('Firestore is not initialized. Call `ensureFirestoreInitialized()` before accessing Firestore.');
  }

  return firebaseApp;
};

const database = (): firebase.firestore.Firestore => {
  if (!db) {
    throw new Error('Firestore is not initialized. Call `ensureFirestoreInitialized()` before accessing Firestore.');
  }

  return db;
};

const collection = (name: string): firebase.firestore.CollectionReference => {
  if (!db) {
    throw new Error('Firestore is not initialized. Call `ensureFirestoreInitialized()` before accessing Firestore.');
  }
  return db.collection(name);
};

const getDocRef = (collectionName: string, docId: string): firebase.firestore.DocumentReference => {
  if (!db) {
    throw new Error('Firestore is not initialized. Call `ensureFirestoreInitialized()` before accessing Firestore.');
  }
  return db.collection(collectionName).doc(docId);
};

const timestamp = (seconds: number, nanoseconds: number): firebase.firestore.Timestamp => {
  return new firebase.firestore.Timestamp(seconds, nanoseconds);
};

const deleteField = (): firebase.firestore.FieldValue => {
  return firebase.firestore.FieldValue.delete();
};

const now = (): firebase.firestore.Timestamp => {
  return firebase.firestore.Timestamp.now();
};

export default {
  app,
  database,
  collection,
  ensureFirestoreInitialized,
};

export type FirestoreFieldValue = firebase.firestore.FieldValue;

export const FirestoreUtils = {
  getDocRef,
  timestamp,
  now,
  deleteField,
};
