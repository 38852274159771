import { Action } from 'redux';
import { BluetoothStatus, NeedsToPairStatus } from 'src/lib/wearable';
import AppThunk from '../interfaces/AppThunk';
import Firestore from 'src/modules/firestore/Firestore';
import IGarminSdkStatus from 'src/modules/initialize/IGarminSdkStatus';

export enum GarminActionTypes {
  SET_GARMIN = 'SET_GARMIN',
  UPDATED_BATTERY = 'UPDATED_BATTERY',
  UPDATING_BATTERY = 'UPDATING_BATTERY',
  CLEAR_GARMIN = 'CLEAR_GARMIN',
  LOGOUT = 'LOGOUT',
  GARMIN_BACKGROUND_SYNC_TIMEOUT = 'GARMIN_BACKGROUND_SYNC_TIMEOUT',
  GARMIN_SYNC_START = 'GARMIN_SYNC_START',
  GARMIN_SYNC_PROGRESS = 'GARMIN_SYNC_PROGRESS',
  GARMIN_SYNC_FAIL = 'GARMIN_SYNC_FAIL',
  GARMIN_SYNC_STOP = 'GARMIN_SYNC_STOP',
  GARMIN_SYNC_SUCCESS = 'GARMIN_SYNC_SUCCESS',
  GARMIN_UPLOAD_START = 'GARMIN_UPLOAD_START',
  GARMIN_UPLOAD_PROGRESS = 'GARMIN_UPLOAD_PROGRESS',
  GARMIN_UPLOAD_FAIL = 'GARMIN_UPLOAD_FAIL',
  GARMIN_UPLOAD_SUCCESS = 'GARMIN_UPLOAD_SUCCESS',
  GARMIN_DB_STATUS = 'GARMIN_DB_STATUS',
  GARMIN_UPDATE_LATEST_UPLOAD = 'GARMIN_UPDATE_LATEST_UPLOAD',
  GEOLOCATION_STATUS_ON = 'GEOLOCATION_STATUS_ON',
  GEOLOCATION_STATUS_OFF = 'GEOLOCATION_STATUS_OFF',
  UPDATE_BLUETOOTH_STATUS = 'UPDATE_BLUETOOTH_STATUS',
  UPDATE_DEVICE_IS_CONNECTED = 'UPDATE_IS_CONNECTED',
  UPDATE_NEEDS_TO_PAIR_ACTION = 'UPDATE_NEEDS_TO_PAIR_ACTION',
  UPDATE_SDK_STATUS = 'UPDATE_SDK_STATUS',
  ADD_SDK_STATUS = 'ADD_SDK_STATUS',
  UPDATE_DEVICE_IS_PAIRING = 'UPDATE_DEVICE_IS_PAIRING',
}

export type GarminActions =
  | SetGarminAction
  | UpdatedBatteryAction
  | UpdatingBatteryAction
  | ClearGarminAction
  | LogoutGarminAction
  | GarminBackgroundSyncTimeoutAction
  | GarminSyncStartAction
  | GarminSyncProgressAction
  | GarminSyncFailAction
  | GarminSyncStopAction
  | GarminSyncSuccessAction
  | UpdateBluetoothStatusAction
  | UpdateDeviceIsConnectedAction
  | GarminUploadStartAction
  | GarminUploadProgressAction
  | GarminUploadFailAction
  | GarminUploadSuccessAction
  | GarminDBStatusAction
  | GarminUpdateLatestUploadAction
  | GeolocationStatusOnAction
  | UpdateNeedsToPairAction
  | UpdateDeviceIsPairingAction
  | GeolocationStatusOffAction
  | AddSdkStatusAction
  | UpdateSdkStatusAction;

export interface SetGarminAction extends Action<GarminActionTypes.SET_GARMIN> {
  name: string | undefined;
  battery: number;
  dataset: number[];
  hourOffset: boolean;
}
export const setGarmin = (name: string | undefined): SetGarminAction => {
  const hourOffset = false;
  const dataset = [1, 2, 3, 4, 5, 6, 7, 8];
  // I'm not sure what this was for, but it seems to have been related to a particular Garmin device in an old SDK?
  // if (name !== undefined && name.search('active') > -1) {
  //   hourOffset = true;
  //   dataset = [1, 2, 3, 4, 5];
  // }
  return {
    type: GarminActionTypes.SET_GARMIN,
    name,
    dataset,
    hourOffset,
    battery: -1,
  };
};

export interface UpdatingBatteryAction extends Action<GarminActionTypes.UPDATING_BATTERY> {
  battery: number;
}

export interface UpdatedBatteryAction extends Action<GarminActionTypes.UPDATED_BATTERY> {
  battery: number;
  lastBatteryAlertSent: Date;
  batteryIsLow: boolean;
}
export const updateBattery = (batteryLevel: number): AppThunk<Promise<UpdatedBatteryAction>, GarminActions> => {
  return async (dispatch, getState) => {
    dispatch({
      type: GarminActionTypes.UPDATING_BATTERY,
      battery: batteryLevel,
    });
    const garminState = getState().garmin;
    let lastBatteryAlertSent = garminState?.lastBatteryAlertSent
      ? new Date(garminState.lastBatteryAlertSent)
      : undefined;
    let batteryIsLow = garminState?.batteryIsLow;
    if (batteryLevel <= 20) {
      const now = new Date();
      console.log(`battery is low at ${batteryLevel} and last alert sent ${lastBatteryAlertSent}`);
      // if battery was previously not low or a notification has not been sent in the last 3 hours:
      const msIn3Hours = 3 * 60 * 60 * 1000;
      console.log(
        `batteryislow, ${batteryIsLow}, lastBatteryAlertSent.getTime thing ${
          lastBatteryAlertSent && lastBatteryAlertSent.getTime() + msIn3Hours < now.getTime()
        }`,
      );
      if (!batteryIsLow || (lastBatteryAlertSent && lastBatteryAlertSent.getTime() + msIn3Hours < now.getTime())) {
        const pir = getState().linkedUsers.selectedLinkedUser?.pir;
        console.log(`pir, ${pir}`);
        if (pir) {
          console.log('sending low battery alert');
          try {
            await Firestore.collection('lowBatteryAlert').doc().set({ pir, created: now, batteryLevel });
            batteryIsLow = true;
            lastBatteryAlertSent = now;
          } catch (err) {
            console.error('Error sending low battery alert', err);
          }
        } else {
          console.log('do not have pir yet when trying to send low battery alert');
        }
      }
    } else if (batteryLevel >= 30 && batteryIsLow) {
      console.log('battery is no longer low at', batteryLevel);
      batteryIsLow = false;
    }
    return dispatch({
      type: GarminActionTypes.UPDATED_BATTERY,
      batteryIsLow: batteryIsLow,
      battery: batteryLevel,
      lastBatteryAlertSent: lastBatteryAlertSent,
    } as UpdatedBatteryAction);
  };
};

export type ClearGarminAction = Action<GarminActionTypes.CLEAR_GARMIN>;
export const clearGarmin = (): ClearGarminAction => {
  return {
    type: GarminActionTypes.CLEAR_GARMIN,
  };
};

export type LogoutGarminAction = Action<GarminActionTypes.LOGOUT>;
export const logoutGarmin = (): LogoutGarminAction => ({
  type: GarminActionTypes.LOGOUT,
});

export type GarminBackgroundSyncTimeoutAction = Action<GarminActionTypes.GARMIN_BACKGROUND_SYNC_TIMEOUT>;
export const garminBackgroundSyncTimeout = (): GarminBackgroundSyncTimeoutAction => ({
  type: GarminActionTypes.GARMIN_BACKGROUND_SYNC_TIMEOUT,
});

export type GarminSyncStartAction = Action<GarminActionTypes.GARMIN_SYNC_START>;
export const garminSyncStart = (): GarminSyncStartAction => ({
  type: GarminActionTypes.GARMIN_SYNC_START,
});

export interface GarminSyncProgressAction extends Action<GarminActionTypes.GARMIN_SYNC_PROGRESS> {
  progress: number;
}
export const garminSyncProgress = (progress: number): GarminSyncProgressAction => ({
  type: GarminActionTypes.GARMIN_SYNC_PROGRESS,
  progress,
});

export interface GarminSyncFailAction extends Action<GarminActionTypes.GARMIN_SYNC_FAIL> {
  errorDetail: string;
}

export const garminSyncFail = (detail?: string): GarminSyncFailAction => ({
  type: GarminActionTypes.GARMIN_SYNC_FAIL,
  errorDetail: detail ?? '',
});

export type GarminSyncStopAction = Action<GarminActionTypes.GARMIN_SYNC_STOP>;

export const garminSyncStop = (): GarminSyncStopAction => ({
  type: GarminActionTypes.GARMIN_SYNC_STOP,
});

export interface GarminSyncSuccessAction extends Action<GarminActionTypes.GARMIN_SYNC_SUCCESS> {
  garminInitiatedSync: boolean;
}
export const garminSyncSuccess = (garminInitiatedSync: boolean): GarminSyncSuccessAction => ({
  garminInitiatedSync,
  type: GarminActionTypes.GARMIN_SYNC_SUCCESS,
});

export interface UpdateBluetoothStatusAction extends Action<GarminActionTypes.UPDATE_BLUETOOTH_STATUS> {
  status: BluetoothStatus | undefined;
}
export const updateBluetoothStatus = (status: BluetoothStatus | undefined): UpdateBluetoothStatusAction => ({
  type: GarminActionTypes.UPDATE_BLUETOOTH_STATUS,
  status,
});

export interface UpdateDeviceIsConnectedAction extends Action<GarminActionTypes.UPDATE_DEVICE_IS_CONNECTED> {
  deviceIsConnected: boolean;
}
export const updateDeviceConnection = (deviceIsConnected: boolean): UpdateDeviceIsConnectedAction => ({
  type: GarminActionTypes.UPDATE_DEVICE_IS_CONNECTED,
  deviceIsConnected,
});

export interface UpdateDeviceIsPairingAction extends Action<GarminActionTypes.UPDATE_DEVICE_IS_PAIRING> {
  isPairing: boolean;
}
export const updateDeviceIsPairing = (isPairing: boolean): UpdateDeviceIsPairingAction => ({
  type: GarminActionTypes.UPDATE_DEVICE_IS_PAIRING,
  isPairing,
});

export interface UpdateSdkStatusAction extends Action<GarminActionTypes.UPDATE_SDK_STATUS> {
  sdkStatusUpdates: Partial<IGarminSdkStatus>;
}
export const updateSavedSdkStatus = (sdkStatusUpdates: Partial<IGarminSdkStatus>): UpdateSdkStatusAction => ({
  type: GarminActionTypes.UPDATE_SDK_STATUS,
  sdkStatusUpdates,
});

export interface AddSdkStatusAction extends Action<GarminActionTypes.ADD_SDK_STATUS> {
  savedSdkStatus: IGarminSdkStatus | undefined;
}
export const addSdkStatus = (savedSdkStatus: IGarminSdkStatus): AddSdkStatusAction => ({
  type: GarminActionTypes.ADD_SDK_STATUS,
  savedSdkStatus,
});

export interface UpdateNeedsToPairAction extends Action<GarminActionTypes.UPDATE_NEEDS_TO_PAIR_ACTION> {
  needsToPair: NeedsToPairStatus;
}
export const updateNeedsToPair = (needsToPair: NeedsToPairStatus): UpdateNeedsToPairAction => ({
  type: GarminActionTypes.UPDATE_NEEDS_TO_PAIR_ACTION,
  needsToPair,
});

export type GarminUploadStartAction = Action<GarminActionTypes.GARMIN_UPLOAD_START>;
export const garminUploadStart = (): GarminUploadStartAction => ({
  type: GarminActionTypes.GARMIN_UPLOAD_START,
});

export interface GarminUploadProgressAction extends Action<GarminActionTypes.GARMIN_UPLOAD_PROGRESS> {
  progress: number;
  lastChunkCompleted: string;
}
export const garminUploadProgress = (progress: number, lastChunkCompleted: string): GarminUploadProgressAction => ({
  type: GarminActionTypes.GARMIN_UPLOAD_PROGRESS,
  progress,
  lastChunkCompleted,
});

export interface GarminUploadFailAction extends Action<GarminActionTypes.GARMIN_UPLOAD_FAIL> {
  errorDetail: string;
}
export const garminUploadFail = (detail?: string): GarminUploadFailAction => ({
  type: GarminActionTypes.GARMIN_UPLOAD_FAIL,
  errorDetail: detail ?? '',
});

export type GarminUploadSuccessAction = Action<GarminActionTypes.GARMIN_UPLOAD_SUCCESS>;
export const garminUploadSuccess = (): GarminUploadSuccessAction => ({
  type: GarminActionTypes.GARMIN_UPLOAD_SUCCESS,
});

export interface GarminDBStatusAction extends Action<GarminActionTypes.GARMIN_DB_STATUS> {
  id: string | undefined;
  pir: string | undefined;
  lastUploadCompleted: string | undefined;
}
export const garminDBStatus = (
  id: string | undefined,
  pir: string | undefined,
  lastUploadCompleted: string | undefined,
): GarminDBStatusAction => ({
  type: GarminActionTypes.GARMIN_DB_STATUS,
  id,
  pir,
  lastUploadCompleted,
});

export interface GarminUpdateLatestUploadAction extends Action<GarminActionTypes.GARMIN_UPDATE_LATEST_UPLOAD> {
  lastUploadCompleted: string | undefined;
}

export const updateLatestUploadTime = (lastUploadCompleted: string | undefined): GarminUpdateLatestUploadAction => ({
  type: GarminActionTypes.GARMIN_UPDATE_LATEST_UPLOAD,
  lastUploadCompleted,
});

export type GeolocationStatusOnAction = Action<GarminActionTypes.GEOLOCATION_STATUS_ON>;
export const geolocationStatusOn = (): GeolocationStatusOnAction => ({
  type: GarminActionTypes.GEOLOCATION_STATUS_ON,
});

export type GeolocationStatusOffAction = Action<GarminActionTypes.GEOLOCATION_STATUS_OFF>;
export const geolocationStatusOff = (): GeolocationStatusOffAction => ({
  type: GarminActionTypes.GEOLOCATION_STATUS_OFF,
});
