import firebase from 'firebase/compat/app';

export default interface IGarminSdkStatus {
  id: string;
  deviceId?: string;
  pir: firebase.firestore.DocumentReference;
  endTime: Date;
  syncErrors: number;
  heartRateLastUpdated?: Date;
  bbiLastUpdated?: Date;
  stepsLastUpdated?: Date;
  stressLastUpdated?: Date;
  zeroCrossingLastUpdated?: Date;
  pulseOxLastUpdated?: Date;
  respirationLastUpdated?: Date;
  accelerometerLastUpdated?: Date;
  dateTime?: Date;
}

export const formatSdkStatusFromFirestore = (
  snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
): IGarminSdkStatus => {
  if (!snapshot.exists) {
    throw new Error('Garmin SDK Status document does not exist in Firestore!');
  }

  let endTime = snapshot.get('endTime');
  let heartRateLastUpdated = snapshot.get('heartRateLastUpdated');
  let bbiLastUpdated = snapshot.get('bbiLastUpdated');
  let stepsLastUpdated = snapshot.get('stepsLastUpdated');
  let stressLastUpdated = snapshot.get('stressLastUpdated');
  let zeroCrossingLastUpdated = snapshot.get('zeroCrossingLastUpdated');
  let pulseOxLastUpdated = snapshot.get('pulseOxLastUpdated');
  let respirationLastUpdated = snapshot.get('respirationLastUpdated');
  let accelerometerLastUpdated = snapshot.get('accelerometerLastUpdated');
  let dateTime = snapshot.get('dateTime');

  if (dateTime) {
    dateTime = dateTime.toDate();
  }

  if (endTime) {
    endTime = endTime.toDate();
  }

  if (heartRateLastUpdated) {
    heartRateLastUpdated = heartRateLastUpdated.toDate();
  }

  if (bbiLastUpdated) {
    bbiLastUpdated = bbiLastUpdated.toDate();
  }

  if (stepsLastUpdated) {
    stepsLastUpdated = stepsLastUpdated.toDate();
  }

  if (stressLastUpdated) {
    stressLastUpdated = stressLastUpdated.toDate();
  }

  if (zeroCrossingLastUpdated) {
    zeroCrossingLastUpdated = zeroCrossingLastUpdated.toDate();
  }

  if (pulseOxLastUpdated) {
    pulseOxLastUpdated = pulseOxLastUpdated.toDate();
  }

  if (respirationLastUpdated) {
    respirationLastUpdated = respirationLastUpdated.toDate();
  }

  if (accelerometerLastUpdated) {
    accelerometerLastUpdated = accelerometerLastUpdated.toDate();
  }

  return {
    id: snapshot.id,
    endTime,
    heartRateLastUpdated,
    bbiLastUpdated,
    stepsLastUpdated,
    stressLastUpdated,
    zeroCrossingLastUpdated,
    pulseOxLastUpdated,
    respirationLastUpdated,
    accelerometerLastUpdated,
    deviceId: snapshot.get('deviceId'),
    syncErrors: snapshot.get('syncErrors'),
    pir: snapshot.get('pir'),
    dateTime,
  };
};
