import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
// import { configureGeolocation } from './modules/geolocation/configureGeolocation';
import * as Sentry from '@sentry/react';
import { Integration, StackFrame } from '@sentry/types';
import { BrowserTracing } from '@sentry/tracing';
import { RewriteFrames } from '@sentry/integrations';

const startApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
};

const startAppCordova = () => {
  // configureGeolocation();
  startApp();
};

if (window.cordova) {
  document.addEventListener('deviceready', startAppCordova, false);
} else {
  startApp();
}

if (process.env.REACT_APP_SENTRY_DSN) {
  const integrations: Integration[] = [new BrowserTracing()];
  if (window.cordova) {
    integrations.unshift(
      new RewriteFrames({
        iteratee: (frame: StackFrame) => {
          if (frame.filename) {
            frame.filename = `cordova:///static/js/${frame.filename.split('/').pop()}`;
          }
          return frame;
        },
      }),
    );
  }
  Sentry.init({
    release: process.env.REACT_APP_NAME + '@' + process.env.REACT_APP_VERSION,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 1.0,
    integrations,
  });

  // Global error listener for uncaught errors
  window.addEventListener('error', (event) => {
    console.error('Global error handler caught:', event.error);
    Sentry.captureException(event.error, {
      extra: {
        message: event.message,
        filename: event.filename,
        lineno: event.lineno,
        colno: event.colno,
      },
    });
  });

  // Global unhandled promise rejection listener
  window.addEventListener('unhandledrejection', (event) => {
    console.error('Unhandled rejection:', event.reason);
    Sentry.captureException(event.reason, {
      extra: {
        details: event.reason,
      },
    });
  });
}
