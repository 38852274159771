import React from 'react';
import { Text, TextProps, Box } from '@chakra-ui/react';

const SecondaryError = (props: TextProps): JSX.Element => (
  <Box mb={3} p={2} borderRadius={8} bg="orange">
    <Text align="center" color="white" fontWeight="500" wordBreak="break-word" overflow="hidden" {...props}>
      {props.children}
    </Text>
  </Box>
);

export default SecondaryError;
