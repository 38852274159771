import React, { useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../redux/store';
import Interior from '../interior/Index';
import Auth from './Index';
import RestoreAccountInfo from './RestoreAccountInfo';
import { consumeInitialLink } from '../initialize/actions';
import { handleDynamicLink } from '../dynamicLinks/dynamicLinkHandler';
import * as Sentry from '@sentry/react';
import { useDevice } from 'src/DeviceContext';
import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { DeletionStatus } from '../user/interfaces/IUser';

const AuthCheck = (props: PropsFromRedux): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const { deviceIsReady } = useDevice();
  const { isMobileDeviceAndCordova } = useDevice();

  useEffect(() => {
    if (isMobileDeviceAndCordova) {
      window.cordova.plugins.firebase.dynamiclinks.onDynamicLink((payload) => {
        if (payload.deepLink) {
          handleDynamicLink(payload.deepLink, history);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (props.initialLinkUrl) {
      dispatch(consumeInitialLink());
      handleDynamicLink(props.initialLinkUrl, history);
    }
  }, [props.initialLinkUrl]);

  if (window.cordova && !deviceIsReady) {
    console.log('device is not ready');
    return (
      <Flex alignItems="center" justifyContent="center" h="100%">
        <Box w="100%" h="25%" textAlign="center">
          <Spinner />
          <Text mt={4} fontSize={24} fontWeight="bold">
            Initializing
          </Text>
        </Box>
      </Flex>
    );
  }

  if (
    query.get('log-in') ||
    query.get('sign-up') ||
    query.get('reset-password') ||
    query.get('straight-to-login') ||
    props.loggingOut ||
    props.loggingIn ||
    props.signingUp ||
    props.resetPassword
  ) {
    return <Auth />;
  } else if (props.user) {
    if (window.cordova) {
      if (props.user.id != props.nativeUserUid) {
        const error = new Error(
          `Forcing user to auth screen because FirebaseUser [${props.user.id}] does not match CordovaFirebaseUser [${props.nativeUserUid}]`,
        );
        console.error(error);
        Sentry.captureException(error);
        return <Auth />;
      }
    }

    if (props.user.deletionStatus === DeletionStatus.DELETING && !props.loggingOut) {
      return (
        <Flex alignItems="center" justifyContent="center" h="100%">
          <Box w="100%" h="25%" textAlign="center">
            <Spinner />
            <Text mt={4} fontSize={24} fontWeight="bold">
              Deleting Account
            </Text>
          </Box>
        </Flex>
      );
    }

    if (props.user.deletionStatus === DeletionStatus.RESTORING) {
      console.log('i think i am restoring');
      return (
        <Flex alignItems="center" justifyContent="center" h="100%">
          <Box w="100%" h="25%" textAlign="center">
            <Spinner />
            <Text mt={4} fontSize={24} fontWeight="bold">
              Restoring Account
            </Text>
          </Box>
        </Flex>
      );
    }

    if (props.user.deletedDatetime) {
      return <RestoreAccountInfo user={props.user} />;
    } else {
      Sentry.setUser({
        id: props.user.id,
        username: props.user.id,
      });
      return <Interior />;
    }
  } else {
    return <Auth />;
  }
};

const mapStateToProps = ({ auth, init, user }: RootState) => {
  return {
    initialLinkUrl: init.initialLinkUrl,
    loading: user.loading,
    loggingIn: auth.loggingIn,
    loggingOut: auth.loggingOut,
    resetPassword: auth.resetPassword,
    signingUp: auth.signingUp,
    user: user.user,
    nativeUserUid: user.nativeUserUid,
  };
};

const mapDispatchToProps = {
  consumeInitialLink,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AuthCheck);
