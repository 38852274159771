import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Offline = (): JSX.Element => {
  const { t } = useTranslation('home');

  return (
    <Flex alignItems="center" justifyContent="center" ml={5} mr={5} height="100vh">
      <Box fontSize={16} textAlign="center" width="full">
        <Text fontWeight="bold">{t('offline.heading')}</Text>
        <Text fontWeight="normal">{t('offline.content')}</Text>
      </Box>
    </Flex>
  );
};

export default Offline;
