import { BluetoothStatus, NeedsToPairStatus } from 'src/lib/wearable';
import { GarminActions, GarminActionTypes } from '../actions/garmin';
import IGarmin from '../interfaces/IGarmin';

const defaultGarmin: IGarmin = {
  id: undefined,
  needsToPair: undefined,
  isPairing: false,
  pir: undefined,
  endTime: undefined,
  name: undefined,
  deviceId: undefined,
  deviceIsConnected: false,
  isSyncing: false,
  syncProgress: undefined,
  lastSyncStarted: undefined,
  lastSyncCompleted: undefined,
  isUploading: false,
  uploadProgress: undefined,
  lastUploadStarted: undefined,
  lastUploadCompleted: undefined,
  battery: -1,
  isUpdatingBattery: false,
  dataset: [],
  syncErrors: 0,
  syncErrorDetails: undefined,
  geolocationStatus: false,
  hourOffset: false,
  backgroundSyncTimeout: false,
  bluetoothStatus: undefined,
  uploadStartTime: undefined,
  syncStartTime: undefined,
  batteryIsLow: undefined,
  lastBatteryAlertSent: undefined,
  savedSdkStatus: undefined,
};

export const garminReducer = (state: IGarmin = defaultGarmin, action: GarminActions): IGarmin => {
  switch (action.type) {
    case GarminActionTypes.SET_GARMIN:
      return {
        ...state,
        name: action.name,
        dataset: action.dataset,
        hourOffset: action.hourOffset,
        syncErrors: 0,
        syncErrorDetails: undefined,
        deviceIsConnected: true,
        needsToPair: NeedsToPairStatus.DOES_NOT_NEED_TO_PAIR,
      };
    case GarminActionTypes.UPDATED_BATTERY:
      return {
        ...state,
        battery: action.battery,
        isUpdatingBattery: false,
        lastBatteryAlertSent: action.lastBatteryAlertSent,
        batteryIsLow: action.batteryIsLow,
      };
    case GarminActionTypes.UPDATING_BATTERY:
      return {
        ...state,
        battery: action.battery,
        isUpdatingBattery: true,
      };
    case GarminActionTypes.CLEAR_GARMIN:
      return {
        ...state,
        name: undefined,
        isSyncing: false,
        isUploading: false,
        uploadStartTime: undefined,
        syncStartTime: undefined,
        battery: 0,
        dataset: [],
        syncErrors: 0,
        syncErrorDetails: undefined,
        deviceIsConnected: false,
      };
    case GarminActionTypes.UPDATE_DEVICE_IS_CONNECTED:
      return {
        ...state,
        deviceIsConnected: action.deviceIsConnected,
        needsToPair: NeedsToPairStatus.DOES_NOT_NEED_TO_PAIR,
      };
    case GarminActionTypes.UPDATE_DEVICE_IS_PAIRING:
      return {
        ...state,
        isPairing: action.isPairing,
      };
    case GarminActionTypes.UPDATE_SDK_STATUS:
      if (state.savedSdkStatus) {
        return {
          ...state,
          savedSdkStatus: { ...state.savedSdkStatus, ...action.sdkStatusUpdates },
        };
      } else {
        console.log('savedSdkStatus is missing but I am trying to update it with', action.sdkStatusUpdates);
        return {
          ...state,
        };
      }
    case GarminActionTypes.ADD_SDK_STATUS:
      return {
        ...state,
        savedSdkStatus: action.savedSdkStatus,
      };
    case GarminActionTypes.UPDATE_NEEDS_TO_PAIR_ACTION:
      return {
        ...state,
        needsToPair: action.needsToPair,
      };
    case GarminActionTypes.GARMIN_BACKGROUND_SYNC_TIMEOUT:
      return {
        ...state,
        isSyncing: false,
        isUploading: false,
        uploadStartTime: undefined,
        syncStartTime: undefined,
        backgroundSyncTimeout: true,
      };
    case GarminActionTypes.GARMIN_SYNC_START:
      return {
        ...state,
        isSyncing: true,
        syncStartTime: Date.now(),
        syncProgress: 0,
        uploadProgress: 0,
        lastSyncStarted: new Date().toISOString(),
        backgroundSyncTimeout: false,
        bluetoothStatus: BluetoothStatus.BLUETOOTH_ON,
        syncErrorDetails: undefined,
        deviceIsConnected: true,
      };
    case GarminActionTypes.GARMIN_SYNC_PROGRESS:
      return {
        ...state,
        isSyncing: true,
        syncStartTime: Date.now(),
        syncProgress: action.progress,
        deviceIsConnected: true,
        backgroundSyncTimeout: false,
      };
    case GarminActionTypes.GARMIN_SYNC_FAIL:
      return {
        ...state,
        isSyncing: false,
        syncStartTime: undefined,
        syncErrors: state.syncErrors + 1,
        backgroundSyncTimeout: false,
        syncErrorDetails: action.errorDetail,
      };
    case GarminActionTypes.GARMIN_SYNC_STOP:
      return {
        ...state,
        isSyncing: false,
        syncStartTime: undefined,
        backgroundSyncTimeout: false,
      };
    case GarminActionTypes.GARMIN_SYNC_SUCCESS:
      return {
        ...state,
        isSyncing: false,
        syncStartTime: undefined,
        syncErrors: 0,
        lastSyncCompleted: action.garminInitiatedSync ? state.lastSyncCompleted : state.lastSyncStarted,
        syncErrorDetails: undefined,
        backgroundSyncTimeout: false,
      };
    case GarminActionTypes.UPDATE_BLUETOOTH_STATUS:
      return {
        ...state,
        bluetoothStatus: action.status,
      };
    case GarminActionTypes.GARMIN_UPLOAD_START:
      return {
        ...state,
        isUploading: true,
        uploadStartTime: Date.now(),
        syncProgress: 100,
        uploadProgress: 0,
        lastUploadStarted: state.lastSyncCompleted,
        backgroundSyncTimeout: false,
      };
    case GarminActionTypes.GARMIN_UPLOAD_PROGRESS:
      return {
        ...state,
        isUploading: true,
        uploadStartTime: Date.now(),
        uploadProgress: action.progress,
        lastUploadCompleted: action.lastChunkCompleted,
      };
    case GarminActionTypes.GARMIN_UPLOAD_FAIL:
      return {
        ...state,
        isUploading: false,
        uploadStartTime: undefined,
        syncErrors: state.syncErrors + 1,
        backgroundSyncTimeout: false,
        syncErrorDetails: action.errorDetail,
      };
    case GarminActionTypes.GARMIN_UPLOAD_SUCCESS:
      return {
        ...state,
        isUploading: false,
        uploadStartTime: undefined,
        syncErrors: 0,
        backgroundSyncTimeout: false,
        syncErrorDetails: undefined,
      };
    case GarminActionTypes.GARMIN_DB_STATUS:
      return {
        ...state,
        id: action.id,
        pir: action.pir,
        lastUploadCompleted: action.lastUploadCompleted,
      };
    case GarminActionTypes.GARMIN_UPDATE_LATEST_UPLOAD:
      return {
        ...state,
        lastUploadCompleted: action.lastUploadCompleted,
      };
    case GarminActionTypes.GEOLOCATION_STATUS_ON:
      return {
        ...state,
        geolocationStatus: true,
      };
    case GarminActionTypes.GEOLOCATION_STATUS_OFF:
      return {
        ...state,
        geolocationStatus: false,
      };
    case GarminActionTypes.LOGOUT:
      return {
        ...defaultGarmin,
        bluetoothStatus: state.bluetoothStatus,
      };

    default:
      return state;
  }
};
